import React, {useCallback, useState, useEffect} from 'react';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import {useApi} from '@backstage/core-plugin-api';
import {useAsync} from "react-use";
import {catalogApiRef, humanizeEntityRef,} from '@backstage/plugin-catalog-react';
import {EntityPickerFieldSchema} from '@backstage/plugin-scaffolder';
import {Entity, parseEntityRef, stringifyEntityRef} from '@backstage/catalog-model';
import Autocomplete from '@material-ui/lab/Autocomplete';

type EntityPickerProps = typeof EntityPickerFieldSchema.type;
export function AadGroupPicker(props: EntityPickerProps) {
  const {
    onChange,
    schema: { title = 'Field title', description = 'Field description' },
    uiSchema,
    required,
    rawErrors,
    formData,
  } = props;
  const catalogApi = useApi(catalogApiRef);
  const defaultKind = uiSchema['ui:options']?.defaultKind;
  const defaultNamespace =
    uiSchema['ui:options']?.defaultNamespace || undefined;

  const { value, loading } = useAsync(() => catalogApi.getEntities({
      filter: {
        kind: ['Group'],
      },
    })
  );

  const groups = value?.items.filter(x=>!x.metadata.name.endsWith('_contributors'));
  const [selectedOption, setSelectedOption] = useState('');

  const handleSelect = useCallback(((option: any) => {
    if (option) {

      const { metadata: { annotations: { "graph.microsoft.com/group-id": groupId } } } = option;
      setSelectedOption(stringifyEntityRef(option as Entity));
      return onChange(groupId);
    }

    setSelectedOption('');
    return onChange('');
  }), [onChange]);

  const getLabel = useCallback(
    (ref: string) => {
      const defaultKindNameSpace = { defaultKind, defaultNamespace };
      try {
        return humanizeEntityRef(
          parseEntityRef(ref, {...defaultKindNameSpace}),
          { ...defaultKindNameSpace },
        );
      } catch (err) {
        return ref;
      }
    },
    [defaultKind, defaultNamespace],
  );

  // @ts-ignore
  const selectedEntity: Entity =
    groups?.find(e => stringifyEntityRef(e) === selectedOption) ??
    (selectedOption ? getLabel(selectedOption) : '');

  useEffect(() => {
    if (formData && groups?.length) {
      const group = groups?.find((e) => {
        const annotations = (e as any)?.metadata?.annotations;
        const groupId = annotations?.["graph.microsoft.com/group-id"];
        return groupId === formData;
      });
      handleSelect(group);
    }
  }, [groups, formData, handleSelect]);

  return (
    <FormControl
      margin="normal"
      required={required}
      error={rawErrors?.length > 0 && !formData}
    >
      <Autocomplete
        disabled={loading}
        onChange={(_e, v) => (handleSelect(v))}
        value={selectedEntity}
        options={groups || []}
        getOptionLabel={(option) => option.metadata?.name || ''}
        getOptionSelected={(option, v) => option.metadata.annotations?.["graph.microsoft.com/group-id"] === v.metadata?.annotations?.["graph.microsoft.com/group-id"]}
        autoSelect
        autoHighlight
        renderInput={params => (
          <TextField
            {...params}
            label={title}
            margin="dense"
            helperText={description}
            FormHelperTextProps={{ margin: 'dense', style: { marginLeft: 0 } }}
            variant="outlined"
            required={required}
            InputProps={params.InputProps}
          />
        )}
      />
    </FormControl>
  );
}
