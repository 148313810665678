import { createApp } from '@backstage/app-defaults';
import { AppRouter, FlatRoutes } from '@backstage/core-app-api';
import { AlertDisplay, OAuthRequestDialog, SignInPage } from '@backstage/core-components';
import { IdentityApi, microsoftAuthApiRef } from '@backstage/core-plugin-api';
import { apiDocsPlugin, ApiExplorerPage } from '@backstage/plugin-api-docs';
import { CatalogEntityPage, CatalogIndexPage, catalogPlugin } from '@backstage/plugin-catalog';
import { catalogEntityCreatePermission } from '@backstage/plugin-catalog-common/alpha';
import { CatalogGraphPage, catalogGraphPlugin } from '@backstage/plugin-catalog-graph';
import { CatalogImportPage, catalogImportPlugin } from '@backstage/plugin-catalog-import';
import { CatalogUnprocessedEntitiesPage } from '@backstage/plugin-catalog-unprocessed-entities';
import { HomepageCompositionRoot } from '@backstage/plugin-home';
import { orgPlugin } from '@backstage/plugin-org';
import { RequirePermission } from '@backstage/plugin-permission-react';
import { ScaffolderPage, scaffolderPlugin } from '@backstage/plugin-scaffolder';
import { ScaffolderFieldExtensions } from '@backstage/plugin-scaffolder-react';
import { SearchPage } from '@backstage/plugin-search';
import { SearchContextProvider } from '@backstage/plugin-search-react';
import { DefaultTechDocsHome, TechDocsIndexPage, techdocsPlugin, TechDocsReaderPage } from '@backstage/plugin-techdocs';
import { ReportIssue } from '@backstage/plugin-techdocs-module-addons-contrib';
import { TechDocsAddons } from '@backstage/plugin-techdocs-react';
import { UserSettingsPage } from '@backstage/plugin-user-settings';
import { setUserToken } from '@internal/backstage-plugin-dh-dock-shared-common';
import { DomainPageAboutCardPage, domainPageAboutCardPlugin } from '@internal/plugin-domain-page-about-card';
import { CssBaseline, ThemeProvider } from '@material-ui/core';
import CodeIcon from '@material-ui/icons/Code';
import { Mermaid } from 'backstage-plugin-techdocs-addon-mermaid';
import React from 'react';
import { Provider } from 'react-redux';
import { Route } from 'react-router-dom';

import { apis } from './apis';
import { entityPage } from './components/catalog/EntityPage';
import { HomePage } from './components/home/HomePage';
import { Root } from './components/Root';
import { searchPage } from './components/search/SearchPage';
import { RadarPage } from './components/techRadar/components';
import { customTheme } from './customTheme';
import { GlobalErrorInterceptor } from './GlobalApiInterceptor';
import Analytics from './pages/Analytics/Analytics';
import { CustomCatalogIndexPage } from './pages/CustomCatalog';
import GuardRails from './pages/GuardRails';
import Report from './pages/Reports';
import { AadGroupPickerFieldExtension } from './pages/templates/AadGroupPicker';
import { AzureDevOpsValidator } from './pages/templates/AzureDevopsValidator/extension';
import { OdpNameExtension } from './pages/templates/Odp/extension';
import { TrackSpaceExtension, TrackSpaceUserListExtension } from './pages/templates/Trackspace-Integration/extension';
import { EntityValidationPage } from './pages/YamlValidation';
import { fetchOwnersAsync } from './redux/slices/ownerShipSlice';
import { dhDockStore } from './redux/store';

const app = createApp({
  apis,
  // support authentication via Azure AD
  components: {
    SignInPage: props => {
      return (
        <SignInPage
          {...props}
          provider={{
            id: 'azure-auth-provider',
            title: 'Azure',
            message: 'Sign in using Azure AD',
            apiRef: microsoftAuthApiRef,
          }}
          onSignInSuccess={async (identityApi: IdentityApi) => {
            const userToken = await identityApi.getCredentials();
            setUserToken(userToken.token!!);
            const state = dhDockStore.getState();
            const owners = state.ownersProps.owners;
            if(owners.length<1){
              dhDockStore.dispatch(fetchOwnersAsync())
              // dhDockStore.dispatch(runComponentAzureDevOpsAsync(userToken.token!!))
            }
            props.onSignInSuccess(identityApi);
          }}
        />
      );
    },
  },
  themes: [
    {
      id: 'custom-theme',
      title: 'DH Custom Theme',
      variant: 'light',
      Provider: ({ children }) => (
        <ThemeProvider theme={customTheme}>
          <CssBaseline>{children}</CssBaseline>
        </ThemeProvider>
      ),
    },
  ],
  featureFlags: [
    {
      pluginId: '',
      name: 'about-card',
      description: 'Enables the custom about card to be displayed',
    },
  ],
  // support custom icons
  // https://backstage.io/docs/getting-started/app-custom-theme#icons
  icons: {
    code: CodeIcon,
  },
  bindRoutes({ bind }) {
    bind(catalogPlugin.externalRoutes, {
      createComponent: scaffolderPlugin.routes.root,
      viewTechDoc: techdocsPlugin.routes.docRoot,
    });
    bind(apiDocsPlugin.externalRoutes, {
      registerApi: catalogImportPlugin.routes.importPage,
    });
    bind(scaffolderPlugin.externalRoutes, {
      registerComponent: catalogImportPlugin.routes.importPage,
    });
    bind(orgPlugin.externalRoutes, {
      catalogIndex: catalogPlugin.routes.catalogIndex,
    });
    bind(catalogGraphPlugin.externalRoutes, {
      catalogEntity: catalogPlugin.routes.catalogEntity,
    });
    bind(domainPageAboutCardPlugin.externalRoutes, {
      createComponent: scaffolderPlugin.routes.root,
      viewTechDoc: techdocsPlugin.routes.docRoot,
    });
  },
});

const routes = (
  <FlatRoutes>
    <Route path="/" element={<HomepageCompositionRoot />}>
      <HomePage />
    </Route>
    <Route path="/catalog" element={<CatalogIndexPage />}>
      <CustomCatalogIndexPage />
    </Route>
    <Route
      path="/catalog/:namespace/:kind/:name/"
      element={<CatalogEntityPage />}
    >
      {entityPage}
    </Route>
    <Route path="/docs" element={<TechDocsIndexPage />}>
      <DefaultTechDocsHome />
    </Route>
    <Route
      path="/docs/:namespace/:kind/:name/*"
      element={<TechDocsReaderPage />}
    >
      <TechDocsAddons>
        <ReportIssue />
        <Mermaid
          darkConfig={{ theme: 'dark' }}
          lightConfig={{ theme: 'forest' }}
        />
      </TechDocsAddons>
    </Route>
    <Route path="/docs" element={<TechDocsIndexPage />} />
    <Route
      path="/graph"
      element={
        <CatalogGraphPage
          initialState={{
            rootEntityRefs: ['group:lufthansa_group'],
            selectedKinds: ['Group', 'Domain', 'System'],
          }}
        />
      }
    />
    <Route path="/create" element={<ScaffolderPage />}>
      <ScaffolderFieldExtensions>
        <AadGroupPickerFieldExtension />
        <AzureDevOpsValidator />
        <OdpNameExtension />
        <TrackSpaceExtension/>
        <TrackSpaceUserListExtension/>
      </ScaffolderFieldExtensions>
    </Route>
    <Route path="/api-docs" element={<ApiExplorerPage />} />
    <Route
      path="/tech-radar"
      element={<RadarPage width={1580} height={500} />}
    />
    <Route path="/entity-validation" element={<EntityValidationPage />} />
    <Route
      path="/catalog-import"
      element={
        <RequirePermission permission={catalogEntityCreatePermission}>
          <CatalogImportPage />
        </RequirePermission>
      }
    />
    <Route path="/search" element={<SearchPage />}>
      {searchPage}
    </Route>
    <Route path="/settings" element={<UserSettingsPage />} />
    <Route path="/guardrails" element={<GuardRails />} />
    <Route path="/catalog-graph" element={<CatalogGraphPage />} />
    <Route
      path="/catalog-unprocessed-entities"
      element={<CatalogUnprocessedEntitiesPage />}
    />

    <Route path="/domain-page-about" element={<DomainPageAboutCardPage />} />

    <Route path="/analytics" element={<Analytics />} />
    <Route path="/report" element={<Report />} />
  </FlatRoutes>
);

export default app.createRoot(
  <>
   <Provider store={dhDockStore}> 
    <AlertDisplay />
    <GlobalErrorInterceptor />
    <OAuthRequestDialog />
    <SearchContextProvider>
    <AppRouter>
      <Root>{routes}</Root>
    </AppRouter>
    </SearchContextProvider>
    </Provider>
  </>,
);
