import { fetchData } from '@internal/backstage-plugin-dh-dock-shared-common';
import { LinearProgress, Paper, TableContainer } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import Drawer from '@mui/material/Drawer';
import React, { useCallback, useEffect, useState } from 'react';
import FilterListIcon from '@material-ui/icons/FilterList';
import AdrList from '../../components/Adr';
import AdrContent from '../../components/Adr/content';
import { ErrorPage } from '../../components/ui/Error';
import {
  getAdrContentBytitle,
  getAdrListByUrl,
} from '../../services/adr.service';
import GuardRailTags from './GuardRail-Tags';
import { guardRailsProps } from './types';
import { useNavigate } from 'react-router-dom';
import { EntityProvider } from '@backstage/plugin-catalog-react';
import { EntityLayout } from '@backstage/plugin-catalog';
import Report from '../Reports';

const sdlcOrder = [
  'Plan',
  'Code',
  'Build',
  'Test',
  'Release',
  'Deploy',
  'Operate',
  'Monitor',
];

const useStyles = makeStyles(() => ({
  NavHamburger: {
    backgroundColor: '#fff',
    height: '50px',
    padding: '15px',
    border: 'none',
    borderRadius: '5px',
    width: '60px',
    '&:hover': {
      cursor: 'pointer', // Change the cursor to pointer on hover
    },
  },
}));

function sdlcProcessData(textData: string) {
  let sdlc: string = '';
  let title: string = '';
  let status: string = '';
  const tags: string[] = [];

  try {
    // Regular expressions for metadata extraction
    const sdlcPattern = /^\s*sdlc\:\s*(.*)$/gm;
    const titlePattern = /^\s*title\:\s*(.*)$/gm;
    const statusPattern = /^\s*status\:\s*(.*)$/gm;
    const tagsPattern = /^\s*tags\:\s*$/gm;
    const tagItemPattern = /^\s*-\s*(.*)$/gm;

    // Split textData by lines and process each line
    const lines = textData.split('\n');
    let insideTagsSection = false;

    lines.forEach(line => {
      // Extract data based on matching patterns
      const sdlcMatch = sdlcPattern.exec(line);
      const titleMatch = titlePattern.exec(line);
      const statusMatch = statusPattern.exec(line);
      const tagsMatch = tagsPattern.exec(line);
      const tagItemMatch = tagItemPattern.exec(line);

      if (sdlcMatch) {
        sdlc = sdlcMatch[1]?.trim();
      } else if (titleMatch) {
        title = titleMatch[1]?.trim();
      } else if (statusMatch) {
        status = statusMatch[1]?.trim();
      } else if (tagsMatch) {
        insideTagsSection = true; // Start of the tags section
      } else if (insideTagsSection && tagItemMatch) {
        tags.push(tagItemMatch[1]?.trim());
      } else if (insideTagsSection && line.trim() === '') {
        insideTagsSection = false; // End of the tags section when encountering an empty line
      }

      // Reset regex state for the next iteration
      sdlcPattern.lastIndex = 0;
      titlePattern.lastIndex = 0;
      statusPattern.lastIndex = 0;
      tagsPattern.lastIndex = 0;
      tagItemPattern.lastIndex = 0;
    });

    return {
      title,
      sdlc,
      status,
      tags,
    };
  } catch (error) {
    return null;
  }
}

const processedData: any[] = [];

const staticEntity = {
  apiVersion: 'backstage.io/v1alpha1',
  kind: '',
  metadata: {
    name: 'Guardrails',
  },
};


const GuardRails = () => {
  const entityName = encodeURIComponent(
    window.location.pathname?.split('/')[4],
  );
  document.title = 'Guard Rails';
  const classes = useStyles();
  const isGuardRailList =
    window.location.pathname.split('/')[1] === 'guardrails';
  const [guardRailState, setGuardRailState] = useState<guardRailsProps>({
    cops: '',
    guardRailList: [],
    sdlc: 'Build',
    status: '',
    adrContent: '',
    tags: '',
    title: '',
    path: '',
    isLoading: true,
    selected: '',
    guardRailLocationPath: '',
    error: null,
    isGuardRailAvailable: false,
    sdlcProcessed: [],
    isNavOpen: false,
    selectedTag: '',
  });
  const navigate = useNavigate();
  const displayedPhases = new Set();
  let name: string = '';
  name = !isGuardRailList ? entityName : '';

  const path = window.location.pathname?.split('/')[6];

  const handleGuardRailClick = (title: string, url?: string) => {
    
    navigate(title);
    fetchFileContentByPath(title, url);
    setGuardRailState(prev => ({
      ...prev,
      selected: title,
    }));
    return;
  };

  const fetchFileContentByPath = useCallback(
    async (title: string, url?: string) => {
      let fileContent: { data: string } = {
        data: '',
      };
      const baseUrl = guardRailState.guardRailLocationPath;
      if (!isGuardRailList) {
        fileContent = await getAdrContentBytitle(baseUrl.concat(title));
      } else {
        fileContent = await getAdrContentBytitle(url!!.concat(title));
      }

      setGuardRailState(prev => ({
        ...prev,
        adrContent: fileContent?.data,
      }));

      return fileContent;
    },
    [guardRailState.guardRailLocationPath, isGuardRailList, path],
  );

  const destructureGuardRailByResponse = useCallback(
    async (fileList: string[], baseUrl: string) => {
      const processedData_: [] = [];
      for (const file of fileList as any) {
        const { path } = file;
        const response = await getAdrContentBytitle(baseUrl.concat(path));
        const processData = sdlcProcessData(response?.data);

        processedData_.push({ ...processData, path, baseUrl } as never);
      }
      processedData_.sort((a: any, b: any) => {
        return (
          sdlcOrder.indexOf(a.sdlc.replace(/\"/g, '')) -
          sdlcOrder.indexOf(b.sdlc.replace(/\"/g, ''))
        );
      });

      return processedData_;
    },
    [],
  );

  const getYaml = useCallback(async () => {
    
    try {
      if (window.location.pathname.split('/')[1] === 'guardrails') {
        // fetch all domain guard rails
        const guardRailList = await fetchData(
          `api/catalog/entities?filter=kind%3Ddomain`,
        );
        if (guardRailList) {
          const allGuardRails = guardRailList.map(
            (guardRail: {
              metadata: {
                annotations: { 'backstage.io/guardrail-location': string };
              };
            }) => {
              return guardRail.metadata.annotations[
                'backstage.io/guardrail-location'
              ];
            },
          ) as string[];

          if (allGuardRails.length > 0) {
            for (const guardrail of allGuardRails.filter(x => x)) {
              const fileList = await getAdrListByUrl(guardrail);

              const response = await destructureGuardRailByResponse(
                fileList?.data,
                guardrail,
              ).then(data => {
                return data;
              });
              processedData.push(response);
            }
          }
          setGuardRailState(prev => ({
            ...prev,
            error: '',
            guardRailList: processedData,
            isLoading: false,
          }));
          return;
        }
      } else {
        const response = await fetchData(
          `api/catalog/entities/by-name/domain/default/${name}`,
        );
        const guardRailLocation =
          response?.metadata?.annotations['backstage.io/guardrail-location'];

        if (response && guardRailLocation) {
          // make call to fetch the files from the location
          const fileList = await getAdrListByUrl(guardRailLocation);
          const response_ = await destructureGuardRailByResponse(
            fileList?.data,
            guardRailLocation,
          ).then(data => {
            return data;
          });
          setGuardRailState(prev => ({
            ...prev,
            isGuardRailAvailable: true,
            error: '',
            guardRailLocationPath: guardRailLocation,
            guardRailList: response_!!,
          }));
        }
        setGuardRailState(prev => ({
          ...prev,
          error: '',
          isLoading: false,
        }));
      }
    } catch (error) {
      if (error instanceof Error) {
        setGuardRailState(prev => ({
          ...prev,
          error: 'an error occured',
          isLoading: false,
        }));
      }
    }
  }, [name, destructureGuardRailByResponse, isGuardRailList]);

  useEffect(() => {
    getYaml();
  }, [getYaml]);

  useEffect(() => {
    

    if (
      window.location.pathname.split('/')[1] === 'guardrails' &&
      guardRailState.guardRailList &&
      guardRailState.guardRailList.length < 1
    ) {
      getYaml();
    } else {
      if (
        guardRailState.guardRailList &&
        guardRailState.guardRailList.length > 0
      ) {
        
        if (!path) {
          const _path =
            [guardRailState.guardRailList[0]]
              .flat()
              ?.find(
                x =>
                  x.path ===
                  window.location.pathname?.split('/')[2]?.replace('%20', ' '),
              )?.path || [guardRailState.guardRailList[0]].flat()[0]?.path;
          handleGuardRailClick(
            !isGuardRailList
              ? [guardRailState.guardRailList[0]].flat()[0]?.path
              : _path,
            [guardRailState.guardRailList[0]].flat()[0]?.baseUrl,
          );
        } else {
          handleGuardRailClick(
            path,
            [guardRailState.guardRailList[0]].flat()[0]?.baseUrl,
          );
        }
      }
    }
  }, [guardRailState.guardRailList, isGuardRailList]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const elements = document.querySelectorAll('.css-wf16b5');

      if (elements.length > 0) {
        // Iterate over each element and change its margin
        elements.forEach(element => {
          const htmlElement = element as HTMLElement; // Cast to HTMLElement
          htmlElement.style.marginLeft = '100px'; // New margin-left value
          htmlElement.style.width = '300px'; // New width value
        });
        // Clear the interval once the elements are found and styles applied
        clearInterval(intervalId);
      }
    }, 100); // Check every 100ms

    // Cleanup the interval if the component unmounts
    return () => clearInterval(intervalId);
  }, [guardRailState.isNavOpen]); // Dependency array contains the variable

  const getUniqueGuardRailHeader = (sdlc: string) => {
    if (!displayedPhases.has(sdlc)) {
      displayedPhases.add(sdlc);
      return sdlc;
    }
    return null;
  };

  const handleNav = () => {
    const { isNavOpen } = guardRailState;
    setGuardRailState(prev => ({
      ...prev,
      isNavOpen: isNavOpen ? false : true,
    }));
  };

  const getTagFilterList = () => {
    const list = [guardRailState.guardRailList[0]].flat().map(item => {
      return item.tags[0];
    });
    const sortedList = list.filter((item, index, self) => {
      return self.indexOf(item) === index;
    });
    return sortedList;
  };

  const onFilter = (tag: string) => {
    const { guardRailList } = guardRailState;
    if (tag) {
      const filteredList = [guardRailList[0]]
        .flat()
        .filter(x => x.tags?.includes(tag));
      const arr: any[] = [];
      arr.push(filteredList);
      setGuardRailState(prev => ({
        ...prev,
        selectedTag: tag,
        isNavOpen: false,
        guardRailList: arr,
      }));
    }
    setGuardRailState(prev => ({
      ...prev,
      selectedTag: tag,
      isNavOpen: false,
    }));
  };

  useEffect(() => {}, []);

  if (!isGuardRailList && guardRailState.isLoading) {
    return <LinearProgress />;
  }

  return (
    <>
      {isGuardRailList && (
        
        <EntityProvider entity={staticEntity} >
      <EntityLayout>
      <EntityLayout.Route path="/guardrail" title="Overview">
       <div
        style={{
          top: isGuardRailList ? '170px' : '190px',
          position: 'absolute',
        }}
      >
        {!guardRailState.isLoading &&
          !guardRailState.error &&
          !guardRailState.isGuardRailAvailable &&
          !isGuardRailList && (
            <ErrorPage message="Guardrail  not found." type="error" />
          )}
        {!guardRailState.isLoading &&
          !guardRailState.error &&
          guardRailState.guardRailList.length > 0 && (
            <div style={{ display: 'flex', flexDirection: 'row', gap: '20px' }}>
              {isGuardRailList && (
                <div
                  onClick={handleNav}
                  className={classes.NavHamburger}
                  role="button"
                  onKeyDown={e => {
                    if (e.key === 'Enter' || e.key === ' ') {
                      handleNav();
                    }
                  }}
                  tabIndex={0}
                >
                  <FilterListIcon />
                </div>
              )}
              <Drawer
                anchor="left"
                open={guardRailState.isNavOpen}
                onClose={handleNav}
              >
                <GuardRailTags
                  filterList={getTagFilterList()}
                  onFilter={onFilter}
                  selected={guardRailState.selectedTag}
                />
              </Drawer>
              <TableContainer
                component={Paper}
                style={{ width: '35rem', padding: '2rem', height: '100vh' }}
              >
                {(!isGuardRailList
                  ? guardRailState.guardRailList
                  : [guardRailState.guardRailList[0]].flat()
                ).map((guardrail, index) => {
                  let header: string | null = '';
                  // Determine the header based on the sdlc field
                  if (guardrail.sdlc) {
                    header = getUniqueGuardRailHeader(guardrail.sdlc);
                  }

                  // Return the rendered component with a unique key for each item
                  return (
                    <React.Fragment
                      key={index.toLocaleString().concat(guardrail.sdlc)}
                    >
                      <h1>{header?.replace(/"/g, '')}</h1>
                      <AdrList
                        name={guardrail.title}
                        title={guardrail.title}
                        path={guardrail.path}
                        status={guardrail.status}
                        selected={guardRailState.selected}
                        onClick={() =>
                          handleGuardRailClick(
                            guardrail.path,
                            isGuardRailList ? guardrail.baseUrl : '',
                          )
                        }
                      />
                    </React.Fragment>
                  );
                })}
              </TableContainer>
              <AdrContent
                baseUrl={guardRailState.guardRailLocationPath}
                retrievedContent={guardRailState.adrContent}
              />
            </div>
          )}
      </div>
      </EntityLayout.Route>
      <EntityLayout.Route path="/reports" title="Reports">
      <div style={{marginTop:'70px'}}>
      <Report url='https://app.powerbi.com/reportEmbed?reportId=274bf69d-ab31-46aa-947d-59778e68fbb0&autoAuth=true&ctid=72e15514-5be9-46a8-8b0b-af9b1b77b3b8'/>
      </div>
      </EntityLayout.Route>
      </EntityLayout>
      </EntityProvider>
        
      )}
     

      
    </>
  );
};

export default GuardRails;
